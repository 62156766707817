import GlobalStateContext from '../../context/globalStateContext'
import { format, getDayOfYear, isToday, isFuture } from 'date-fns'
import React from 'react'
import { CSVLink } from 'react-csv'

const botrytisCellColor = (d) => {
  if (d < 0.5) return 'level-1'
  if (d >= 0.5 && d < 0.7) return 'level-3'
  if (d >= 0.7) return 'level-4'
}

const anthracnoseCellColor = (d) => {
  if (d < 0.15) return 'level-1'
  if (d >= 0.15 && d < 0.5) return 'level-3'
  if (d >= 0.5) return 'level-4'
}

export default function ResultsTable({ station, modelData, data }) {
  const { resultsTable } = modelData.elements
  const { dateOfInterest } = React.useContext(GlobalStateContext)

  const csvData = data.map((d) => {
    return {
      date: d.dateDisplay,
      botrytisDailyInfectionRisk: d.botrytisDailyInfectionRisk,
      anthracnoseDailyInfectionRisk: d.anthracnoseDailyInfectionRisk,
    }
  })
  let tableData = [...data]
  if (data.length >= 8) {
    tableData = data.slice(
      dateOfInterest.dayOfYear - 3,
      dateOfInterest.dayOfYear + 5,
    )
  }
  tableData = tableData.map((d) => {
    return {
      ...d,
      dayOfYear: getDayOfYear(d.date),
      isForecast: isToday(d.date) || isFuture(d.date),
    }
  })
  // console.log(tableData)
  if (!data) return null
  return (
    <div className="w-full">
      <div className="flex items-center justify-between mb-3">
        <h2 className="text-xl font-semibold text-gray-600 md:text-2xl">
          {resultsTable.title}
        </h2>

        <div className="flex justify-center rounded-md shadow-sm">
          <button
            type="button"
            aria-label="download results table data in csv format"
            className="inline-flex items-center p-2 text-sm font-medium leading-4 text-white transition duration-150 ease-in-out border border-transparent rounded-md sm:px-3 sm:py-2 bg-secondary-800 hover:bg-secondary-700 focus:outline-none focus:border-secondary-900 focus:shadow-outline-secondary active:bg-secondary-900"
          >
            <svg
              className="-ml-0.5 mr-2 h-4 w-4"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"></path>
            </svg>

            <CSVLink
              className="text-white no-underline"
              filename={`${modelData.title} Results Table.csv`}
              data={csvData}
            >
              <span className="hidden text-white sm:inline-block">
                Download
              </span>{' '}
              <span className="text-white">CSV</span>
            </CSVLink>
          </button>
        </div>
      </div>

      <div className="flex flex-col my-4 sm:flex-row sm:justify-between sm:items-center">
        <div className="flex items-center">
          <span className="inline-block py-2 mr-4">
            <a
              className="text-xs sm:text-sm"
              href={`http://forecast.weather.gov/MapClick.php?textField1=${station.lat}&textField2=${station.lon}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Forecast Details
            </a>
          </span>
        </div>
        <div className="flex flex-col mt-2 text-sm lg:mt-0 lg:items-center lg:flex-row">
          <span className="mb-2 font-bold tracking-wider text-gray-700 lg:mb-0 lg:mr-3">
            Infection Risk Levels:
          </span>
          <div className="grid grid-cols-3 gap-1 lg:gap-2">
            <span className="cell level-1">Low</span>
            <span className="cell level-2">Moderate</span>
            <span className="cell level-4">High</span>
          </div>
        </div>
      </div>

      <div className="flex flex-col mt-4">
        <div className="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
          <div className="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg">
            <table className="table min-w-full">
              <thead>
                <tr>
                  <th
                    className="px-6 py-3 text-xs leading-4 tracking-wider text-center text-white uppercase border-r border-gray-200 bg-secondary-800"
                    rowSpan="2"
                  >
                    Date <small>({dateOfInterest.year})</small>
                    <span className="block mt-2 lowercase">13:00 To 12:00</span>
                  </th>
                  <th
                    className="px-6 py-3 text-xs leading-4 tracking-wider text-center text-white border-b border-r border-gray-200 bg-secondary-800"
                    colSpan="2"
                  >
                    Infection Risk Levels
                  </th>
                </tr>
                <tr className="text-center">
                  <th className="px-6 py-3 text-xs leading-4 tracking-wider text-white border-b border-r border-gray-200 bg-secondary-800 lg:w-80 ">
                    <div className="uppercase">Botrytis</div>
                    <div className="flex flex-col mx-auto mt-2 space-y-1 lg:space-x-1 lg:w-80 lg:flex-row lg:justify-center lg:items-center lg:space-y-0">
                      <span className="cell level-1">&#60; 0.50</span>
                      <span className="cell level-2">
                        &#8805; 0.50 and &#60; 0.70
                      </span>
                      <span className="cell level-4">&#8805; 0.70</span>
                    </div>
                  </th>
                  <th className="px-6 py-3 text-xs leading-4 tracking-wider text-white border-b border-r border-gray-200 bg-secondary-800 lg:w-80 ">
                    <div className="uppercase">Anthracnose</div>
                    <div className="flex flex-col mx-auto mt-2 space-y-1 lg:space-x-1 lg:w-80 lg:flex-row lg:justify-center lg:items-center lg:space-y-0">
                      <span className="cell level-1">&#60; 0.15</span>
                      <span className="cell level-2">
                        &#8805; 0.15 and &#60; 0.50
                      </span>
                      <span className="cell level-4">&#8805; 0.50</span>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {tableData.map((day, i) => {
                  return (
                    <tr
                      key={i}
                      className={
                        i === 2 ? `font-extrabold text-center` : `text-center`
                      }
                    >
                      <td
                        className={`text-xs sm:text-sm px-6 h-4 border-b border-gray-200 leading-6 text-gray-700`}
                      >
                        <span className="flex flex-col items-center">
                          {format(day.date, 'LLLL d')}
                          <small className="font-bold text-gray-500">
                            {day.isForecast ? 'Forecast' : ''}
                          </small>
                        </span>
                      </td>
                      <td
                        className={`text-xs sm:text-sm px-6 py-4 border-b border-gray-200 leading-6 text-gray-700`}
                      >
                        <span
                          className={`cell ${botrytisCellColor(
                            +day.botrytisDailyInfectionRisk,
                          )}`}
                        >
                          {+day.botrytisDailyInfectionRisk}
                        </span>
                      </td>
                      <td
                        className={`text-xs sm:text-sm px-6 py-4 border-b border-gray-200 leading-6`}
                      >
                        <span
                          className={`cell ${anthracnoseCellColor(
                            +day.anthracnoseDailyInfectionRisk,
                          )}`}
                        >
                          {+day.anthracnoseDailyInfectionRisk}
                        </span>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}
