import React from "react"

const Notes = () => {
  return (
    <>
      <h2 className="my-4 text-lg font-bold">
        NOTE: the key factors affecting disease susceptibility:
      </h2>
      <ul className="ml-4 text-gray-700 list-disc list-outside">
        <li className="py-2">
          <b>History of the disease in the planting:</b> if outbreaks of either
          disease have occurred in previous seasons to a greater or lesser
          extent, then the present risk of disease is greater or lesser,
          respectively.
        </li>
        <li className="py-2">
          <b>Planting age and potential inoculum build-up:</b> older plantings
          have higher levels of disease inoculum build-up from previous seasons.
        </li>
        <li className="py-2">
          <b>Crop phenology:</b> Both diseases can infect flowers in bloom and
          wounded fruit at harvest. The fungi causing anthracnose can infect
          other tissues including the crown and petioles.{" "}
        </li>
        <li className="py-2">
          <b>Renovation practices the previous year:</b> effective renovation
          promotes healthy plantings, reducing risk to both diseases.
        </li>
        <li className="py-2">
          <b>Nitrogen:</b> spring applications of nitrogen can increase the
          potential for Botrytis infection and high levels of nitrogen in soil
          favor anthracnose.
        </li>
      </ul>
    </>
  )
}

function dateRangeToMsg(el, dateOfInterest) {
  for (const [key, message] of Object.entries(el)) {
    const dateRange = key.split("|")
    const lowerDate = `${dateOfInterest.date.getFullYear()}-${dateRange[0]}`
    const lowerDateMs = new Date(lowerDate).getTime()
    const upperDate = `${dateOfInterest.date.getFullYear()}-${dateRange[1]}`
    const upperDateMs = new Date(upperDate).getTime()
    if (dateOfInterest.ms >= lowerDateMs && dateOfInterest.ms <= upperDateMs) {
      return message.diseaseManagement
    }
  }
}

export default function ManagementGuide({
  hasBloom,
  hasHarvest,
  modelData,
  dateOfInterest,
  considerations,
}) {
  const { managementGuide } = modelData.elements

  let message = dateRangeToMsg(managementGuide.message, dateOfInterest)
  if (hasBloom || hasHarvest) message = "MESSAGE_3"

  return (
    <div>
      <h2 className="mb-8 text-xl font-semibold text-gray-600 md:text-2xl">
        Management Guide
      </h2>

      {/* Message 1 */}
      {message === "MESSAGE_1" && (
        <div className="p-4 mb-4 bg-white rounded-md shadow-lg lg:p-6">
          <p>
            Infection risk model results will display beginning February 15. To
            see model results for previous seasons, select an ending date of
            interest in the range from March 16 to October 31.
          </p>
          <Notes></Notes>
        </div>
      )}

      {/* Message 2 */}
      {message === "MESSAGE_2" && (
        <div className="p-4 mb-4 bg-white rounded-md shadow-lg lg:p-6">
          {considerations}
          <Notes></Notes>
        </div>
      )}

      {/* Message 3 */}
      {/* Botrytis Table */}
      {message === "MESSAGE_3" && (
        <>
          <div className="flex flex-col">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200 table-fixed">
                    <thead className="bg-secondary-800">
                      <tr>
                        <th
                          scope="col"
                          className="w-1/2 px-6 py-3 text-base font-semibold tracking-wider text-left text-white "
                        >
                          Botrytis Fruit Rot or Gray Mold
                        </th>
                        <th
                          scope="col"
                          className="w-1/2 px-6 py-3 text-base font-semibold tracking-wider text-left text-white"
                        >
                          Botrytis Management
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="bg-white">
                        <td className="px-6 py-4 text-sm text-gray-500">
                          Lesions remain firm and brown while fruit is green.
                          Lesions expand and soften as fruit ripens. A powdery
                          gray mass of spores may cover infected berries.
                        </td>
                        <td className="px-6 py-4 text-sm text-gray-500">
                          Infections occur primarily Protect flowers when
                          Infection Risk is moderate or high. Monitor fields and
                          harvests for gray mold. Protect fruit if disease is
                          present and Botrytis Infection Risk is moderate or
                          high. during bloom. from early
                        </td>
                      </tr>
                      <tr className="bg-white">
                        <td className="px-6 py-4 text-lg font-bold text-gray-500">
                          Botrytis Infection Risk Levels
                        </td>
                        <td className="px-6 py-4 text-sm text-gray-500"></td>
                      </tr>
                      <tr className="bg-white">
                        <td className="px-6 py-4 text-sm font-extrabold text-gray-500">
                          Low &#60; 0.5
                        </td>
                        <td className="px-6 py-4 text-sm text-gray-500">
                          No need for fungicides against Botrytis.
                        </td>
                      </tr>
                      <tr className="bg-white">
                        <td className="px-6 py-4 text-sm font-extrabold text-gray-500">
                          Moderate &#8805; 0.5 and &#60; 0.7
                        </td>
                        <td className="px-6 py-4 text-sm text-gray-500">
                          Take into account the susceptibility. If several
                          factors contribute to greater susceptibility, apply a
                          fungicide if there has been no application for 7-14
                          days.
                        </td>
                      </tr>
                      <tr className="bg-white">
                        <td className="px-6 py-4 text-sm font-extrabold text-gray-500">
                          High &#8805; 0.7
                        </td>
                        <td className="px-6 py-4 text-sm text-gray-500">
                          If no fungicides have been applied in the last 7-14
                          days, apply a highly effective fungicide as soon as
                          possible.
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          {/* Anthracnose Table */}
          <div className="flex flex-col mt-4">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200 table-fixed">
                    <thead className="bg-secondary-800">
                      <tr>
                        <th
                          scope="col"
                          className="w-1/2 px-6 py-3 text-base font-semibold tracking-wider text-left text-white"
                        >
                          Anthracnose Fruit Rot
                        </th>
                        <th
                          scope="col"
                          className="w-1/2 px-6 py-3 text-base font-semibold tracking-wider text-left text-white"
                        >
                          Anthracnose Management
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="bg-white">
                        <td className="px-6 py-4 text-sm text-gray-500">
                          One or more circular, tan or light brown spots usually
                          about 1/8 to 1/4 inch in diameter occur on both green
                          and ripe fruit and become sunken and darker. On ripe
                          fruit, lesions may be sunken and filled with pink
                          slimy spore masses.
                        </td>
                        <td className="px-6 py-4 text-sm text-gray-500">
                          Open flowers and ripening and ripe fruit are most
                          susceptible to anthracnose. The disease is favored
                          during warm, wet weather conditions. Monitor fields
                          and harvests for anthracnose and quickly begin a
                          management program once the disease is detected and
                          Anthracnose Infection Risk is moderate or high.
                        </td>
                      </tr>
                      <tr className="bg-white">
                        <td className="px-6 py-4 text-lg font-bold text-gray-500">
                          Anthracnose Infection Risk Levels
                        </td>
                        <td className="px-6 py-4 text-sm text-gray-500"></td>
                      </tr>
                      <tr className="bg-white">
                        <td className="px-6 py-4 text-sm font-extrabold text-gray-500">
                          Low &#60; 0.15
                        </td>
                        <td className="px-6 py-4 text-sm text-gray-500">
                          No need for fungicides against anthracnose.
                        </td>
                      </tr>
                      <tr className="bg-white">
                        <td className="px-6 py-4 text-sm font-extrabold text-gray-500">
                          Moderate &#8805; 0.15 and &#60; 0.5
                        </td>
                        <td className="px-6 py-4 text-sm text-gray-500">
                          Take into account the susceptibility. If several
                          factors contribute to greater susceptibility, apply a
                          fungicide if there has been no application for 7-14
                          days.
                        </td>
                      </tr>
                      <tr className="bg-white">
                        <td className="px-6 py-4 text-sm font-extrabold text-gray-500">
                          High &#8805; 0.5
                        </td>
                        <td className="px-6 py-4 text-sm text-gray-500">
                          If no fungicides have been applied in the last 7-14
                          days, apply a highly effective fungicide as soon as
                          possible.
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="p-4 my-4 bg-white rounded-md shadow-lg lg:p-6">
            <p>
              <b>When a prior fungicide interval is in place.</b> After applying
              a fungicide and during the labeled fungicide interval (often 7-14
              days), ignore the risk levels. If ≥1 inch of rain falls during the
              spray interval, the fungicide has likely weathered off, therefore
              follow the infection risk levels and apply a fungicide
              accordingly. As the spray interval expires, assess and re-assess
              the risk levels and apply a fungicide accordingly.
            </p>
            <Notes></Notes>
            {considerations}
          </div>
        </>
      )}

      {/* Message 4 */}
      {message === "MESSAGE_4" && (
        <div className="p-4 mb-4 bg-white rounded-md shadow-lg lg:p-6">
          <p>
            Botrytis conidia (spores) overwinter and are produced on infected
            strawberry leaf residues. Weed and straw residue may also promote
            survival and contribute to Botrytis inoculum. Infested plant residue
            is the principal source of infections during bloom. Therefore,
            monitoring fruit for Botrytis infection during harvests will provide
            a perspective on how important sanitation (the removal of leaf and
            crop debris from the field) will be in order to reduce overwintering
            inoculum.
          </p>

          <p>
            Anthracnose overwinters on infected strawberry plant debris.
            Infected plant residue is the principal source of infections during
            bloom. Therefore, monitoring fruit for anthracnose infection during
            harvest will provide a perspective on how common infections on other
            plant parts might be and how important sanitation (the removal of
            leaf and crop debris from the field) will be in order to reduce
            overwintering inoculum.
          </p>
        </div>
      )}
    </div>
  )
}
