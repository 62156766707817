import React from "react"

export default function References() {
  return (
    <>
      <p className="text-sm leading-5 text-left text-gray-500">
      Bulger, M. A., Ellis, M. A., and Madden, L. V. 1987. Influence of temperature and wetness duration on infection of strawberry flowers by Botrytis cinerea and disease incidence of fruit originating from infected flowers. Phytopathology 77:1225-1230.
      </p>
      <p className="text-sm leading-5 text-left text-gray-500">
      MacKenzie, S. J., and N. A. Peres. 2012a. “Use of leaf wetness and temperature to time fungicide applications to control anthracnose rot of strawberry in Florida.” Plant Disease 96: 522–28.
      </p>
      <p className="text-sm leading-5 text-left text-gray-500">
      MacKenzie, S. J., and N. A. Peres. 2012b. Use of leaf wetness and temperature to time fungicide applications to control Botrytis fruit rot of strawberry in Florida. Plant Dis. 96: 529–36.
      </p>
      <p className="text-sm leading-5 text-left text-gray-500">
      Wilson, L.L., Madden, L.V. and Ellis, M.A. 1990. Influence of temperature and wetness duration on infection of immature and mature strawberry fruit by Colletotrichum acutatum. Phytopathology 80:111-116
      </p>
    </>
  )
}
