import { fahrenheitToCelcius } from "../../utils/helpers"
import { format, isEqual, subHours } from "date-fns"

const botrytisIndex = (W, T) => {
  const i = -4.268 + 0.0294 * W * T - 0.0901 * W - 0.0000235 * W * T ** 3
  return 1 / (1 + Math.exp(-i))
}

const anthracnoseIndex = (W, T) => {
  const i =
    -3.7 + 0.33 * W - 0.069 * W * T + 0.005 * W * T ** 2 - 0.000093 * W * T ** 3
  return 1 / (1 + Math.exp(-i))
}

export default function strawberryDiseasesLogic(data, dateOfInterest) {
  const { hourlyData, hourlyFcstData } = data
  const hourlyDataTotal = [...hourlyData, ...hourlyFcstData]

  let noonToNoon = []
  let p = {
    date: null,
    lwet: [],
    tempF: [],
    tempC: [],
    prcp: [],
    lwetHours: [],
    wetEvents: [],
    botrytisDailyInfectionRisk: 0,
    anthracnoseDailyInfectionRisk: 0,
  }

  hourlyDataTotal.forEach((d) => {
    let hour = d.date.getHours()
    if (hour >= 0 && hour <= 12) hour += 24

    if (hour >= 13 && hour <= 36) {
      p.date = d.date
      p.ms = d.ms
      p.dateDisplay = format(d.date, "yyyy-MM-dd")
      p.lwet.push(d.lwet)
      p.tempF.push(d.temp)
      p.tempC.push(
        d.temp === "M" ? "M" : Math.round(fahrenheitToCelcius(d.temp))
      )
      p.prcp.push(d.prcp)

      if (hour === 36) {
        noonToNoon.push(p)
        p = {
          date: null,
          lwet: [],
          tempF: [],
          tempC: [],
          prcp: [],
          lwetHours: [],
          wetEvents: [],
          botrytisDailyInfectionRisk: 0,
          anthracnoseDailyInfectionRisk: 0,
        }
      }
    }
  })
  // console.log(noonToNoon)
  const resultsTable = noonToNoon.map((d) => {
    d.lwet.forEach((w, j) => {
      if (w !== 0 && w !== "M") {
        d.wetEvents.push({ hour: j, temp: d.tempC[j] })
      }
    })

    let wetEventsCount = []
    let arr = []
    if (d.wetEvents.length !== 0) {
      if (d.wetEvents.length === 1) {
        wetEventsCount.push([d.wetEvents[0]])
      } else {
        d.wetEvents.forEach((e, i) => {
          if (i === 0) {
            arr.push(e)
          } else {
            if (e.hour - d.wetEvents[i - 1].hour < 4) {
              arr.push(e)
            } else {
              wetEventsCount.push(arr)
              arr = []
              arr.push(e)
            }
            if (i === d.wetEvents.length - 1) wetEventsCount.push(arr)
          }
        })
      }
      // console.log(d.date, wetEventsCount)
      let botrytisDailyInfectionRisk = []
      let anthracnoseDailyInfectionRisk = []
      if (wetEventsCount.length !== 0) {
        d.wetEventsCount = wetEventsCount
        wetEventsCount.forEach((arr, i) => {
          const w = arr.length // ask Dan
          const avgT =
            arr.map((d) => d.temp).reduce((acc, val) => acc + val, 0) /
            arr.length

          botrytisDailyInfectionRisk.push(botrytisIndex(w, avgT))
          anthracnoseDailyInfectionRisk.push(anthracnoseIndex(w, avgT))

          if (i === wetEventsCount.length - 1) {
            d.botrytisDailyInfectionRisk = Math.max(
              ...botrytisDailyInfectionRisk
            ).toFixed(2)
            d.anthracnoseDailyInfectionRisk = Math.max(
              ...anthracnoseDailyInfectionRisk
            ).toFixed(2)
            botrytisDailyInfectionRisk = []
            anthracnoseDailyInfectionRisk = []
          }
        })
      }
    }
    return d
  })
  // console.log(resultsTable)

  let splittedWetEvents = []
  let wetEvents = []
  let lwetCount = 0

  let firstIdx = 0
  const marchIdx = hourlyData.findIndex((d) =>
    isEqual(d.date, new Date(dateOfInterest.year, 2, 1, 0, 0))
  )
  if (marchIdx !== -1) firstIdx = marchIdx
  let lastIdx = hourlyData.findIndex((d) =>
    isEqual(d.date, new Date(dateOfInterest.year, 10, 1, 0, 0))
  )
  if (lastIdx === -1) lastIdx = hourlyData.length

  hourlyData.slice(firstIdx, lastIdx).forEach((d) => {
    if (d.lwet !== 0) {
      if (lwetCount >= 4) {
        splittedWetEvents.push(wetEvents)
        wetEvents = [d]
      } else {
        wetEvents.push(d)
      }
      lwetCount = 0
    } else {
      lwetCount++
    }
  })
  // console.log(splittedWetEvents)

  const wetEventSummary = splittedWetEvents
    .filter((arr) => arr.length !== 0)
    .map((arr, i) => {
      const ddd = arr[0].date
      const wetEventStartDateTime = subHours(ddd, 1)
      const wetEventEndDateTime = arr.slice(-1)[0].date
      const hoursLW = arr.length

      let avgTempF = "-"
      const temps = arr.map((d) => d.temp)
      if (temps.length !== 0) {
        avgTempF = (
          temps.reduce((acc, val) => acc + val, 0) / temps.length
        ).toFixed(0)
      }

      let totalRain = "-"
      const prcp = arr
        .filter((d) => d.prcp !== "M")
        .filter((d) => d.prcp !== undefined)
        .map((d) => d.prcp)
      if (prcp.length !== 0) {
        totalRain = prcp.reduce((acc, val) => acc + val, 0).toFixed(2)
      }
      return {
        idx: i,
        wetEventStartDateTime: format(
          wetEventStartDateTime,
          "M/d/yyyy h:00 aaa"
        ),
        wetEventEndDateTime: format(wetEventEndDateTime, "M/d/yyyy h:00 aaa"),
        hoursLW,
        avgTempF,
        totalRain,
      }
    })

  return { resultsTable, wetEventSummary }
}
