import React from 'react'
import { CSVLink } from 'react-csv'

export default function EnviroTable({ modelData, data }) {
  const { resultsTable2 } = modelData.elements

  if (!data) return null
  return (
    <div className="w-full">
      <div className="flex items-center justify-between mb-3">
        <h2 className="text-xl font-semibold text-gray-600 md:text-2xl">
          {resultsTable2.title}
        </h2>

        <div className="flex justify-center rounded-md shadow-sm">
          <button
            type="button"
            aria-label="download results table data in csv format"
            className="inline-flex items-center p-2 text-sm font-medium leading-4 text-white transition duration-150 ease-in-out border border-transparent rounded-md sm:px-3 sm:py-2 bg-secondary-800 hover:bg-secondary-700 focus:outline-none focus:border-secondary-900 focus:shadow-outline-secondary active:bg-secondary-900"
          >
            <svg
              className="-ml-0.5 mr-2 h-4 w-4"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"></path>
            </svg>

            <CSVLink
              className="text-white no-underline"
              filename={`${modelData.title} Table.csv`}
              data={data}
            >
              <span className="hidden text-white sm:inline-block">
                Download
              </span>{' '}
              <span className="text-white">CSV</span>
            </CSVLink>
          </button>
        </div>
      </div>

      <div className="flex flex-col mt-8">
        <div className="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
          <div className="inline-block min-w-full overflow-y-auto align-middle border-b border-gray-200 shadow h-128 sm:rounded-lg">
            <table className="table min-w-full">
              <thead>
                <tr>
                  <th className="sticky top-0 px-6 py-4 text-xs leading-4 tracking-wider text-white border-b border-r border-gray-200 lg:text-sm bg-secondary-800 lg:w-80">
                    Wet Starting Date Time
                  </th>
                  <th className="sticky top-0 px-6 py-4 text-xs leading-4 tracking-wider text-white border-b border-r border-gray-200 lg:text-sm bg-secondary-800 lg:w-80">
                    Wet Ending Date Time
                  </th>
                  <th className="sticky top-0 px-6 py-4 text-xs leading-4 tracking-wider text-white border-b border-r border-gray-200 lg:text-sm bg-secondary-800 lg:w-80">
                    Hours LW
                  </th>
                  <th className="sticky top-0 px-6 py-4 text-xs leading-4 tracking-wider text-white border-b border-r border-gray-200 lg:text-sm bg-secondary-800 lg:w-80">
                    Avg Temp (˚F)
                  </th>
                  <th className="sticky top-0 px-6 py-4 text-xs leading-4 tracking-wider text-white border-b border-r border-gray-200 lg:text-sm bg-secondary-800 lg:w-80">
                    Total Rain
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {data.length === 0 ? (
                  <tr className="px-6 py-4 text-xs leading-6 text-center text-gray-700 border-b border-gray-200 sm:text-sm">
                    <td colSpan="5">No Wetting Events</td>
                  </tr>
                ) : (
                  data.reverse().map((day) => {
                    return (
                      <tr key={day.idx} className="text-center">
                        <td
                          className={`text-xs sm:text-sm px-6 py-4 border-b border-gray-200 leading-6 text-gray-700`}
                        >
                          {day.wetEventStartDateTime}
                        </td>
                        <td
                          className={`text-xs sm:text-sm px-6 py-4 border-b border-gray-200 leading-6 text-gray-700`}
                        >
                          {day.wetEventEndDateTime}
                        </td>
                        <td
                          className={`text-xs sm:text-sm px-6 py-4 border-b border-gray-200 leading-6 text-gray-700`}
                        >
                          <span>{day.hoursLW}</span>
                        </td>
                        <td
                          className={`text-xs sm:text-sm px-6 py-4 border-b border-gray-200 leading-6 text-gray-700`}
                        >
                          <span>{day.avgTempF}</span>
                        </td>
                        <td
                          className={`text-xs sm:text-sm px-6 py-4 border-b border-gray-200 leading-6`}
                        >
                          <span>{day.totalRain}</span>
                        </td>
                      </tr>
                    )
                  })
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}
