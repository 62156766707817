import { isAfter } from "date-fns";
import React from "react";
import { useSnackbar } from "react-simple-snackbar";
import ModelLayout from "../../components/models/modelLayout";
import Toggle from "../../components/models/toggle";
import GlobalStateContext from "../../context/globalStateContext";
import { useAuth0 } from "../../context/react-auth0-spa";
import { modelInSeason, sdateEdate } from "../../utils/helpers";
import useFetchStationData from "../../utils/hooks/useFetchStationData";
import useStations from "../../utils/hooks/useStations";
import modelData from "./strawberry-diseases.json";
import Acknowledgments from "./_acknowledgements";
import EnviroTable from "./_enviroTable";
import ManagementGuide from "./_managementGuide";
import modelLogic from "./_modelLogic";
import MoreInfo from "./_moreInfo";
import References from "./_references";
import ResultsTable from "./_resultsTable";

const visibleElements = Object.entries(modelData.elements)
  .filter(([_, value]) => value.priority)
  .filter(([key, _]) => !key.includes("user"))
  .map((arr) => ({ ...arr[1], toggleName: arr[0] }))
  .sort((a, b) =>
    a.priority > b.priority ? 1 : b.priority > a.priority ? -1 : 0
  );

const Considerations = () => {
  return (
    <>
      <h2 className="font-bold">
        Strawberry Botrytis and Anthracnose Infection Risk Model Considerations:
      </h2>
      <ul className="mt-4 ml-4 list-disc list-outside">
        <li className="py-1">
          These infection risk tools are intended for field-grown strawberries
          and work equally well for June-bearing and day neutral varieties.
        </li>
        <li className="py-1">
          The calculated infection risk may be applicable to low tunnel
          production, if the low tunnel sides were pulled up, allowing water
          (rain, irrigation) to wet plants.
        </li>
        <li className="py-1">
          The calculated infection risk may not be applicable to high tunnel
          production.
        </li>
        <li className="py-1">
          Use either the Botrytis Infection Risk Level or the Anthracnose
          Infection Risk Level to manage the disease of greatest concern.
        </li>
        <li className="py-1">
          Fungicide applications are suggested when “Infection Risk Levels” are
          moderate or high and there has been more than 7-14 days since the last
          application or more than 1” of rain. High risk indicates that weather
          conditions are highly conducive to infection and highly efficacious
          fungicides should be used for either disease.
        </li>
        <li className="py-1">
          Disease risk is calculated on a 24-hour clock, from noon to noon.
        </li>
      </ul>
    </>
  );
};

export default function StrawberryDiseases() {
  const h1Text = modelData.title;
  const { seasonEnd, id: modelId } = modelData;
  const { user, setUser, isAuthenticated } = useAuth0();
  const { stationList, station, setStation, favoriteStations, geoJSON } =
    useStations();
  const { dateOfInterest } = React.useContext(GlobalStateContext);
  const [openSnackbar] = useSnackbar();
  const isModelInSeason = modelInSeason(dateOfInterest, modelData);

  const { sdate, edate } = sdateEdate(dateOfInterest, seasonEnd);
  const { isLoading, data } = useFetchStationData(station, sdate, edate);

  const [hasBloom, setHasBloom] = React.useState(false);
  const [hasHarvest, setHasHarvest] = React.useState(false);
  let mData = null;
  if (data && isModelInSeason) {
    mData = modelLogic(data, dateOfInterest);
  }

  const [isMoreInfo, setIsMoreInfo] = React.useState(false);
  const [showManagementGuide, setShowManagementGuide] = React.useState(true);
  const [showResultsTable, setShowResultsTable] = React.useState(true);
  const [showEnvTable, setShowEnvTable] = React.useState(true);
  const isVisible = station && mData;

  function handleToggleHasBloom() {
    if (user) {
      if (Object.keys(user.activeTools)) {
        const model = user.activeTools.find((model) => model.id === modelId);
        if (model) {
          const activeToolsUpdated = user.activeTools.filter(
            (model) => model.id !== modelId
          );
          if (
            Object.keys(model["hasBloom"]).includes(`${dateOfInterest.year}`)
          ) {
            model["hasBloom"][dateOfInterest.year][station.id] = !hasBloom;
          } else {
            model["hasBloom"][dateOfInterest.year] = {
              [station.id]: !hasBloom,
            };
          }
          setUser({
            ...user,
            activeTools: [...activeToolsUpdated, model],
          });
          openSnackbar("Updating Database...");
        }
      }
    }
    setHasBloom(!hasBloom);
  }

  function handleToggleHasHarvest() {
    if (user) {
      if (Object.keys(user.activeTools)) {
        const model = user.activeTools.find((model) => model.id === modelId);
        if (model) {
          const activeToolsUpdated = user.activeTools.filter(
            (model) => model.id !== modelId
          );
          if (
            Object.keys(model["hasHarvest"]).includes(`${dateOfInterest.year}`)
          ) {
            model["hasHarvest"][dateOfInterest.year][station.id] = !hasHarvest;
          } else {
            model["hasHarvest"][dateOfInterest.year] = {
              [station.id]: !hasHarvest,
            };
          }
          setUser({
            ...user,
            activeTools: [...activeToolsUpdated, model],
          });
          openSnackbar("Updating Database...");
        }
      }
    }
    setHasHarvest(!hasHarvest);
  }
  React.useEffect(() => {
    if (!user) return;
    if (Object.keys(user.activeTools)) {
      const model = user.activeTools.find((model) => model.id === modelId);
      if (model) {
        if (
          Object.keys(model.hasBloom).includes(`${dateOfInterest.year}`) &&
          Object.keys(model.hasBloom[dateOfInterest.year]).includes(station.id)
        ) {
          setHasBloom(model.hasBloom[dateOfInterest.year][station.id]);
        } else {
          setHasBloom(false);
        }

        if (
          Object.keys(model.hasHarvest).includes(`${dateOfInterest.year}`) &&
          Object.keys(model.hasHarvest[dateOfInterest.year]).includes(
            station.id
          )
        ) {
          setHasHarvest(model.hasHarvest[dateOfInterest.year][station.id]);
        } else {
          setHasHarvest(false);
        }
      }
    }
  }, [user, dateOfInterest.year, station, modelId]);

  return (
    <ModelLayout
      isAuthenticated={isAuthenticated}
      stationList={user ? favoriteStations : stationList}
      station={station}
      setStation={setStation}
      allStations={stationList}
      geoJSON={geoJSON}
      data={data}
      isModelInSeason={isModelInSeason}
      isLoading={isLoading}
      modelData={modelData}
      visibleElements={visibleElements}
      titleSize={"text-lg lg:text-2xl"}
      moreInfo={<MoreInfo moreInfo={modelData.moreInfo} />}
      setIsMoreInfo={setIsMoreInfo}
      references={References}
      acknowledgments={Acknowledgments}
      isMoreInfo={isMoreInfo}
      showManagementGuide={showManagementGuide}
      setShowManagementGuide={setShowManagementGuide}
      showResultsTable={showResultsTable}
      setShowResultsTable={setShowResultsTable}
      showResultsTable2={showEnvTable}
      setShowResultsTable2={setShowEnvTable}
      tutorialLink={modelData.tutorialLink}
      areStationsFiltered={false}
      h1Text={h1Text}
    >
      {/* hasBloom Toggle */}
      <div className="mb-16 sm:mb-18 md:mb-20">
        {isVisible &&
          isAfter(
            dateOfInterest.date,
            new Date(dateOfInterest.year, 1, 16, 0, 0)
          ) && (
            <div className="flex items-center justify-center">
              <h2 className="mb-2 text-xl font-semibold text-gray-600 md:text-2xl">
                Has bloom begun?
              </h2>
              <Toggle
                text={``}
                showItem={hasBloom}
                setShowItem={handleToggleHasBloom}
              ></Toggle>
            </div>
          )}

        {isVisible &&
          hasBloom &&
          isAfter(
            dateOfInterest.date,
            new Date(dateOfInterest.year, 10, 1, 0, 0)
          ) && (
            <div className="flex items-center justify-center">
              <h2 className="mb-2 text-xl font-semibold text-gray-600 md:text-2xl">
                Is harvest still underway?
              </h2>
              <Toggle
                text={``}
                showItem={hasHarvest}
                setShowItem={handleToggleHasHarvest}
              ></Toggle>
            </div>
          )}
      </div>

      {/* Results Table */}
      {isModelInSeason && showResultsTable && isVisible && (
        <div className="mb-16 sm:mb-18 md:mb-20">
          <ResultsTable
            station={station}
            modelData={modelData}
            data={mData.resultsTable}
            dateOfInterest={dateOfInterest}
          ></ResultsTable>
        </div>
      )}

      {/* Management Guide */}
      {isModelInSeason && showManagementGuide && isVisible && (
        <div className="mb-16 sm:mb-18 md:mb-20">
          <ManagementGuide
            modelData={modelData}
            hasBloom={hasBloom}
            hasHarvest={hasHarvest}
            dateOfInterest={dateOfInterest}
            considerations={<Considerations></Considerations>}
          ></ManagementGuide>
        </div>
      )}

      {/* Environmental Values Table */}
      {isModelInSeason && showEnvTable && isVisible && (
        <div className="mb-16 sm:mb-18 md:mb-20">
          <EnviroTable
            modelData={modelData}
            data={mData.wetEventSummary}
            hasBloom={hasBloom}
            considerations={<Considerations></Considerations>}
          ></EnviroTable>
        </div>
      )}
    </ModelLayout>
  );
}
