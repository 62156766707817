import React from "react"

export default function MoreInfo() {
  return (
    <ul className="px-4 mt-6 text-sm text-left list-disc">
      <li className="mb-4">
        <a
          href="https://ecommons.cornell.edu/handle/1813/43079 "
          target="_blank"
          rel="noopener noreferrer"
        >
          Botrytis Fruit Rot
        </a>
      </li>
    </ul>
  )
}
